import apiClient from './apiConfig';

interface ProductCategory {
    id: number;
    description: string;
    image?: File | string;
}

interface ProductCategoryResponse {
    success: boolean;
    data: ProductCategory[];
    message: string;
}

const productCategoriesService = {
    // Obtener todas las categorias
    getAllCategories: async (): Promise<ProductCategory[]> => {
        const response = await apiClient.get<ProductCategoryResponse>('/products/categories');
        return response.data.data;
    },

    addCategory: async (categoryData: FormData): Promise<void> => {
        const response = await apiClient.post('/products/categories', categoryData,  {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    },
}

export default productCategoriesService;
export type { ProductCategory };