import React, { useState, useEffect } from 'react';
import Layout from 'antd/es/layout';
import Sider from 'antd/es/layout/Sider';
import Logo from './components/Logo';
import MenuList from './components/MenuList';
import { Content, Header } from 'antd/es/layout/layout';
import { Button, theme } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Route, Routes, Navigate } from 'react-router-dom';

import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import QuestionsPage from './pages/QuestionsPage';
import ConnectionsPage from './pages/ConnectionsPage';
import PassengersPage from './pages/PassengersPage';
import PromotionsPage from './pages/PromotionsPage';
import ProductCategoriesPage from './pages/ProductCategoriesPage';
import ProductsPage from './pages/ProductsPage';

const App = () => {
  const [darkTheme,] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // Nuevo estado de carga

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
    setLoading(false); // Termina de cargar después de obtener el token
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px'
      }}>
        Cargando...
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={authToken ? "/home" : "/login"} />} />
      <Route path="/login" element={!authToken ? <LoginPage /> : <Navigate to="/home" />} />
      <Route path="*" element={
        authToken ? (
          <Layout style={{ minHeight: '100%' }}>
            <Sider collapsed={collapsed} className="sidebar" theme={darkTheme ? 'dark' : 'light'}>
            <Logo collapsed={collapsed} />
              <MenuList darkTheme={darkTheme} />
            </Sider>
            <Layout>
              <Header style={{ display: 'flex', justifyContent: 'space-between', background: colorBgContainer, paddingLeft: '10px', paddingRight: '10px' }}>
                <div>
                  <Button icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => setCollapsed(!collapsed)}></Button>
                </div>
                <div></div>
              </Header>
              <Content>
                {/* 
                <div style={{ padding: '10px' }}>
                  <small>Home / Dashboard</small>
                </div>
                */}
                <div style={{ paddingLeft: '10px', paddingTop: '10px', width: '99%' }}>
                  <Routes>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/questions" element={<QuestionsPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/connections" element={<ConnectionsPage />} />
                    <Route path="/passengers" element={<PassengersPage />} />
                    <Route path="/promotions" element={<PromotionsPage />} />
                    <Route path="/products" element={<ProductsPage />} />
                    <Route path="/products/categories" element={<ProductCategoriesPage />} />
                  </Routes>
                </div>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <Navigate to="/login" />
        )
      } />
    </Routes>
  );
};

export default App;
