import apiClient from './apiConfig';

export const login = async (email: string, password: string) => {
  try {
    const response = await apiClient.post('/login', {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await apiClient.post('/logout', {});
    return response.data;
  } catch (error) {
    throw error;
  }
};


