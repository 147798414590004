import { useEffect, useState } from "react";
import productCategoryService from '../services/productCategoryService';
import { Button, Card, Col, Row, Spin, Modal, Form, Input, Upload, message } from "antd";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

const BASE_IMAGE_URL = 'https://chiavari.sbcode.com.ar/api/public/storage/litos/categories/';

const ProductCategoriesPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await productCategoryService.getAllCategories();
        setData(data);
        console.log(data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const handleAddCategory = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('description', values.description);
      // Extraer el archivo de la lista de archivos subidos
      if (values.image && values.image.fileList.length > 0) {
        formData.append('image', values.image.fileList[0].originFileObj); // Aquí se asegura que se añade correctamente el archivo
      } else {
        throw new Error('Debe subir una imagen válida.');
      }
      
      await productCategoryService.addCategory(formData); // Aquí llamas a tu servicio para hacer el POST
      message.success('Categoría agregada con éxito');
      setIsModalVisible(false);
      form.resetFields();
      
      // Recargar las categorías después de agregar una nueva
      const updatedData = await productCategoryService.getAllCategories();
      setData(updatedData);
    } catch (error) {
      message.error('Error al agregar la categoría');
      console.error('Failed to add category:', error);
    }
  };

  if (loading) {
    return (
      <Spin tip="Cargando..." size="large">
        <div style={{ padding: 50, background: 'rgba(0, 0, 0, 0.05)', borderRadius: 4 }} />
      </Spin>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <Row style={{ marginBottom: 16 }}>
        <h1>Categorías</h1>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddCategory}>
          Agregar
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        {data.map((category) => (
          <Col span={6} key={category.id}>
            <Card
              hoverable
              cover={<img alt={category.description} src={`${BASE_IMAGE_URL}${category.image}`} style={{ height: 200, objectFit: 'cover' }} />}
            >
              <Card.Meta title={category.description} />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        title="Agregar Categoría"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Guardar"
        cancelText="Cancelar"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="description"
            label="Descripción"
            rules={[{ required: true, message: 'Por favor ingrese una descripción' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Imagen"
            valuePropName="file"
            rules={[{ required: true, message: 'Por favor suba una imagen' }]}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Impide la subida automática
            >
              <Button icon={<UploadOutlined />}>Subir Imagen</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductCategoriesPage;
