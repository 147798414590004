import apiClient from './apiConfig';

interface Product {
    id: number;
    description: string;
    image?: File | string;
    price: number
}

interface CreateProductResponse {
    success: boolean;
    data: Product;
    message: string;
  }

const productService = {
    getAllProducts: async (page: number, perPage: number, search: string = '') => {
        const response = await apiClient.get(`/products?page=${page}&per_page=${perPage}&search=${encodeURIComponent(search)}`);
        console.log(response.data);
        return response.data;
    },

    // Crear un nuevo producto
    createProduct: async (productData: FormData) => {
      const response = await apiClient.post<CreateProductResponse>('/products', productData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },

}

export default productService;
export type { Product }