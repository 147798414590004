import React, { useEffect, useState } from 'react';
import { Collapse, Spin, Alert, Button, Modal, Form, Input, message } from 'antd';
import { fetchQuestions, createQuestion, updateQuestion } from '../services/questionService';

interface Question {
  id: number;
  question: string;
  answer: string;
  order: number;
  created_at: string;
  updated_at: string;
}

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

const QuestionPage: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const data = await fetchQuestions();
        setQuestions(data);
      } catch (error) {
        setError('Failed to load questions.');
      } finally {
        setLoading(false);
      }
    };

    getQuestions();
  }, []);

  const showModal = (question?: Question) => {
    setIsModalOpen(true);
    if (question) {
      setCurrentQuestion(question);
      form.setFieldsValue(question);
    } else {
      setCurrentQuestion(null);
      form.resetFields();
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      if (currentQuestion) {
        // Llamada a la API para actualizar la pregunta existente
        await updateQuestion(currentQuestion.id, values);
        const updatedQuestions = questions.map(q =>
          q.id === currentQuestion.id ? { ...q, ...values } : q
        );
        setQuestions(updatedQuestions);
        message.success('Pregunta actualizada con éxito');
      } else {
        // Llamada a la API para agregar una nueva pregunta
        const newQuestion = await createQuestion(values);
        setQuestions([...questions, newQuestion]);
        message.success('Pregunta agregada con éxito');
      }

      setIsModalOpen(false);
    } catch (error) {
      message.error('Error al guardar la pregunta');
      console.error('Validate Failed:', error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <Spin tip="Cargando..." size="large">
        {content}
      </Spin>
    );
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  const items = questions.map((question) => ({
    key: question.id.toString(),
    label: (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{question.question}</span>
        <Button type="link" onClick={() => showModal(question)}>
          Editar
        </Button>
      </div>
    ),
    children: <p>{question.answer}</p>,
  }));

  return (
    <div style={{padding: '20px'}}>
      <h1 style={{marginBottom: '20px'}}>Preguntas Frecuentes</h1>
      <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => showModal()}>
        Agregar
      </Button>

      <Collapse items={items} />
      
      <Modal
        title={currentQuestion ? 'Editar Pregunta' : 'Agregar Pregunta'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="question"
            label="Pregunta"
            rules={[{ required: true, message: 'Por favor ingresa la pregunta' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="answer"
            label="Respuesta"
            rules={[{ required: true, message: 'Por favor ingresa la respuesta' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="order"
            label="Orden"
            rules={[{ required: true, message: 'Por favor ingresa el orden' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default QuestionPage;
