// src/pages/HomePage.tsx
import { Card, Col, Row } from 'antd';
import React from 'react';

const HomePage = () => {
  return(
    <div style={{ padding: '20px' }}>
      <Row gutter={16} style={{textAlign:'center'}}>
        <Col span={8}>
          <Card title="Pasajeros" bordered={false}>
              <div style={{fontSize:'28px'}}>00</div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Promociones" bordered={false}>
              <div style={{fontSize:'28px'}}>00</div>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Registro semanal" bordered={false}>
              <div style={{fontSize:'28px'}}>00</div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
