import React from 'react';
import { Row, Col, Form, Input, Button, Typography, message } from 'antd';
import { CSSProperties } from 'react';

import LogoImage from '../assets/images/logo1.png'; 
import TravelImage from '../assets/images/4420.jpg';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authenticationService';

const { Title, Text } = Typography;

const styles: { [key: string]: CSSProperties } = {
  container: {
    padding: '100px',
    height: '100vh',
    display: 'flex',
  },
  leftCol: {
    backgroundImage: `url(${TravelImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    padding: '50px',
  },
  logo: {
    maxWidth: '150px',
    marginBottom: '20px', // Espacio entre el logo y el título
  },
  rightCol: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  form: {
    fontFamily: 'Poppins',
    maxWidth: '300px',
    marginTop: '20px',
  },
  fullWidthButton: {
    fontFamily: 'Poppins',
    width: '100%',
  },
  redLink: {
    color: 'red',
  },
};

const LoginPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate(); // Hook para la navegación

  const handleLogin = async (values: any) => {
    setLoading(true);
    try {
      const response = await login(values.email, values.password);

      if (response.success) {
        const { token } = response.data;
        localStorage.setItem('authToken', token);
        message.success('Bienvenido!');
        navigate('/home'); // Navega a la página de inicio
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error('Datos incorrectos. Por favor intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row style={styles.container}>
      <Col span={12} style={styles.leftCol}>
      </Col>
      <Col span={12} style={styles.rightCol}>
        <img src={LogoImage} alt="Logo" style={styles.logo} />
        <Title level={2} style={{fontFamily: 'Poppins'}}>¡Hola!</Title> 
        <Text style={{fontFamily: 'Poppins'}}>Completá tus datos y empezá a operar.</Text>
        <Form 
          name="login"
          style={styles.form}
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Por favor ingrese su usuario' }]}
          >
            <Input placeholder="Email" style={{fontFamily: 'Poppins'}} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Por favor ingrese su clave' }]}
          >
            <Input.Password placeholder="Clave" style={{fontFamily: 'Poppins'}} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={styles.fullWidthButton} loading={loading}>
              Ingresar
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
