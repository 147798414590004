import apiClient from './apiConfig'; 

export const getPassengers = async (page: number, perPage: number, search: string = '') => {
  try {
    const response = await apiClient.get(`/passengers?page=${page}&per_page=${perPage}&search=${encodeURIComponent(search)}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching passengers:', error);
    throw error;
  }
};





