// Logo.tsx
import React from 'react';
import logoFull from '../assets/images/logo3.png';
import logoSmall from '../assets/images/logo3Small.png'; // Añade una versión pequeña del logo si la tienes.

const Logo = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img 
        src={collapsed ? logoSmall : logoFull} 
        alt="Logo" 
        style={{ width: collapsed ? '40px' : '120px', transition: 'width 0.3s' }} 
      />
    </div>
  );
};

export default Logo;
