import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Card, Form, Input, DatePicker, Switch, Upload, Row, Col, Spin } from 'antd';
import { EditOutlined, EyeOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import promotionService, { Promotion } from '../services/promotionService';

const BASE_IMAGE_URL = 'https://chiavari.sbcode.com.ar/api/public/storage/promotions/';

const { Dragger } = Upload;

const formatDate = (dateString: string) => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format('YYYY-MM-DD') : '';
};

const parseDate = (dateString: string) => {
  return dayjs(dateString, 'YYYY-MM-DD');
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const PromotionsPage: React.FC = () => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const data = await promotionService.getAllPromotions();
        setPromotions(data);
        console.log(data);
      } catch (error) {
        console.error('Failed to fetch promotions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPromotions();
  }, []);

  const handleView = (promotion: Promotion) => {
    setSelectedPromotion(promotion);
    setIsViewModalVisible(true);
  };

  const handleEdit = (promotion: Promotion) => {
    setSelectedPromotion(promotion);
    setIsModalVisible(true);
    form.setFieldsValue({
      ...promotion,
      expiry_date: promotion.expiry_date ? parseDate(promotion.expiry_date) : null,
      main_image: promotion.main_image ? [{
        uid: '-1',
        name: promotion.main_image,
        status: 'done',
        url: `${BASE_IMAGE_URL}${promotion.main_image}`
      }] : [],
      images: (promotion.images || []).map((img, index) => ({
        uid: (index + 1).toString(),
        name: img.image_path,
        status: 'done',
        url: `${BASE_IMAGE_URL}${img.image_path}`
      }))
    });
  };

  const handleAddPromotion = () => {
    setSelectedPromotion(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedPromotion(null);
    form.resetFields();
  };

  const handleViewModalClose = () => {
    setIsViewModalVisible(false);
    setSelectedPromotion(null);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const formData = new FormData();
  
      // Si hay una imagen principal, la agregamos a FormData
      if (values.main_image && values.main_image[0]?.originFileObj) {
        formData.append('main_image', values.main_image[0].originFileObj);
      } else {
        // Si no hay imagen principal, podemos lanzar un error
        throw new Error('La imagen principal es requerida.');
      }
      // Agregar imágenes adicionales
      if (values.images && values.images.fileList) {
        values.images.fileList.forEach((file: any, index: number) => {
          formData.append(`images[${index}]`, file.originFileObj);
        });
      }
  
      // Agregar el resto de los campos del formulario a FormData
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('is_active', values.is_active ? '1' : '0');
      formData.append('expiry_date', values.expiry_date.format('YYYY-MM-DD'));
  
      if (selectedPromotion) {
        // Editar promoción existente
        await promotionService.updatePromotion(selectedPromotion.id, formData);
      } else {
        // Agregar nueva promoción
        await promotionService.createPromotion(formData);
      }
  
      const updatedPromotions = await promotionService.getAllPromotions();
      setPromotions(updatedPromotions);
      handleModalClose();
    } catch (error) {
      console.error('Failed to save promotion:', error);
    }
  };

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Activa',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text: boolean) => (text ? 'Si' : 'No'),
    },
    {
      title: 'Vencimiento',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text: string) => formatDate(text),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_: any, record: Promotion) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
          />
        </span>
      ),
    },
  ];

  const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  
  const content = <div style={contentStyle} />;

  if (loading) {
    return (
      <Spin tip="Cargando..." size="large">
        {content}
      </Spin>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <Row style={{ marginBottom: 16 }}>
        <h1>Promociones</h1>
      </Row>
      <Row style={{ marginBottom: '20px'}}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddPromotion}>
          Agregar
        </Button>
      </Row>
      <Table columns={columns} dataSource={promotions} rowKey="id" />

      <Modal
        title={selectedPromotion ? "Editar Promocion" : "Agregar Promocion"}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="title"
            label="Titulo"
            rules={[{ required: true, message: 'Debe ingresar un titulo!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Descripción"
            rules={[{ required: true, message: 'Debe ingresar la descripción!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="is_active"
                label="Activo"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="expiry_date"
                label="Vencimiento"
                rules={[{ required: true, message: 'Debe ingresar el vencimiento!' }]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="main_image"
            label="Imagen Principal"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Dragger
              accept='image/*'
              maxCount={1}
              beforeUpload={() => false} // Evita la carga automática
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Haga clic o arrastre archivos aquí</p>
            </Dragger>
          </Form.Item>
          <Form.Item
            name="images"
            label="Imagenes Secundarias"
          >
            <Dragger beforeUpload={() => false} multiple fileList={form.getFieldValue('images')}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Haga clic o arrastre archivos aquí</p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {selectedPromotion ? "Guardar" : "Agregar"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Vista Previa"
        visible={isViewModalVisible}
        onCancel={handleViewModalClose}
        footer={null}
      >
        {selectedPromotion && (
          <Card
            cover={selectedPromotion.main_image ? <img alt="main" src={`${BASE_IMAGE_URL}${selectedPromotion.main_image}`} style={{ width: '100%', height: 'auto' }} /> : null}
          >
            <Card.Meta title={selectedPromotion.title} description={selectedPromotion.description} />
          </Card>
        )}
      </Modal>
    </div>
  );
};

export default PromotionsPage;
