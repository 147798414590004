import React, { useState, useEffect } from 'react';
import { Table, Pagination, Input, Spin } from 'antd';
import { getPassengers } from '../services/userService';

const { Search } = Input;

const PassengersPage: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [search, setSearch] = useState<string>('');

  const fetchData = async (page: number, pageSize: number, search: string = '') => {
    setLoading(true); // Start loading when fetching data
    try {
      const result = await getPassengers(page, pageSize, search);
      setData(result.data);
      setPagination(prev => ({
        ...prev,
        total: result.total,
      }));
    } catch (error) {
      console.error('Error fetching passengers:', error);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  const { current, pageSize } = pagination;

  useEffect(() => {
    
    fetchData(current, pageSize, search);
  }, [current, pageSize, search]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination(prev => ({
      ...prev,
      current: 1,
    }));
  };

  const handleTableChange = (page: number, pageSize: number) => {
    setPagination(prev => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Apellido', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Teléfono', dataIndex: 'user_data.phone', key: 'phone' }
    // Agrega más columnas según sea necesario
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ marginBottom: '20px' }}>Pasajeros</h1>
      <Search
        placeholder="Buscar pasajeros"
        onSearch={handleSearch}
        enterButton
        style={{ marginBottom: '20px', maxWidth: '300px' }}
      />
      <Spin spinning={loading} tip="Cargando...">
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </Spin>
      <Pagination
        style={{ marginTop: '10px' }}
        align="end"
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default PassengersPage;
