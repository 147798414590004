import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Modal, Form, Input, message, Spin, Alert } from 'antd';
import { fetchConnections, createQuestion, updateQuestion } from '../services/connectionService';

interface Connection {
  id: number;
  name: string;
  password: string;
  created_at: string;
  updated_at: string;
}

const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  
const content = <div style={contentStyle} />;

const ConnectionsPage: React.FC = () => {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentConnection, setCurrentConnection] = useState<Connection | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const getConnections = async () => {
      try {
        const data = await fetchConnections();
        
        setConnections(data);
      } catch (error) {
        setError('Failed to load connections.');
      } finally {
        setLoading(false);
      }
    };

    getConnections();
  }, []);

  const showModal = (connection?: Connection) => {
    setIsModalOpen(true);
    if (connection) {
      setCurrentConnection(connection);
      form.setFieldsValue(connection);
    } else {
      setCurrentConnection(null);
      form.resetFields();
    }
  };

  const handleOk = async () => {
    try {
        const values = await form.validateFields();

        if (currentConnection) {
            // Llamada a la API para actualizar la conexión existente
            const updatedConnection = await updateQuestion(currentConnection.id, values);
            const updatedConnections = connections.map(c =>
                c.id === currentConnection.id ? updatedConnection : c
            );
            setConnections(updatedConnections);
            message.success('Conexión actualizada con éxito');
        } else {
            // Llamada a la API para agregar una nueva conexión
            const newConnection = await createQuestion(values);
            setConnections([...connections, newConnection]); // Agregamos la nueva conexión al estado
            message.success('Conexión agregada con éxito');
        }

        const data = await fetchConnections();
        setConnections(data);

        setIsModalOpen(false);
        form.resetFields();
    } catch (error) {
        message.error('Error al guardar la conexión');
        console.error('Validate Failed:', error);
    }
    };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <Spin tip="Cargando..." size="large">
        {content}
      </Spin>
    );
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Row style={{ marginBottom: '20px' }}>
        <h1>Acceso a Internet</h1>
      </Row>
      <Row>
        <Button type="primary" style={{ marginBottom: '20px' }} onClick={() => showModal()}>
          Agregar
        </Button>
      </Row>
      <Row gutter={16}>
        
        {connections.map(connection => (
            
            <Col span={8} key={connection.id || Math.random()}>
            <Card
              style={{marginBottom: '10px'}}
              title={connection.name}
              bordered={false}
              extra={<Button type="link" onClick={() => showModal(connection)}>Editar</Button>}
            >
              {connection.password}
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title={currentConnection ? 'Editar Conexión' : 'Agregar Conexión'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Nombre"
            rules={[{ required: true, message: 'Por favor ingresa el nombre de la conexión' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Contraseña"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ConnectionsPage;
