import React, { useState, useEffect } from 'react';
import { Table, Pagination, Input, Spin, Button, Row, Modal, Form, Input as AntdInput, Select, Upload, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import productService from '../services/productService';
import categoryService from '../services/productCategoryService'; // Asegúrate de tener un servicio para las categorías

const { Search } = Input;
const { Option } = Select;

const ProductsPage: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [search, setSearch] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<any[]>([]);

  const fetchData = async (page: number, pageSize: number, search: string = '') => {
    setLoading(true);
    try {
      const result = await productService.getAllProducts(page, pageSize, search);
      console.log(result);
      setData(Array.isArray(result.data.data) ? result.data.data : []);
      setPagination(prev => ({
        ...prev,
        total: result.data.total,
      }));
    } catch (error) {
      console.error('Error fetching products:', error);
      setData([]); // Fallback en caso de error
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const result = await categoryService.getAllCategories(); // Asegúrate de tener un servicio para obtener categorías
      setCategories(result);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const { current, pageSize } = pagination;

  useEffect(() => {
    
    fetchData(current, pageSize, search);
    fetchCategories();
  }, [current, pageSize, search]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination(prev => ({
      ...prev,
      current: 1,
    }));
  };

  const handleTableChange = (page: number, pageSize: number) => {
    setPagination(prev => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handleAddProduct = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append('description', values.description);
      formData.append('category_id', values.category_id);
      formData.append('price', values.price);
      if (values.image && values.image.fileList.length > 0) {
        formData.append('image', values.image.fileList[0].originFileObj); // Aquí se asegura que se añade correctamente el archivo
      } else {
        throw new Error('Debe subir una imagen válida.');
      }

      await productService.createProduct(formData);
      message.success('Producto agregado con éxito');
      setIsModalVisible(false);
      fetchData(pagination.current, pagination.pageSize, search); // Refetch data to show new product
    } catch (error) {
      message.error('Error al agregar el producto');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Descripción', dataIndex: 'description', key: 'description' },
    { title: 'Categoría', dataIndex: ['category', 'description'], key: 'category' },
    { title: 'Precio', dataIndex: 'price', key: 'price' },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Row style={{ marginBottom: 16 }}>
        <h1>Productos del menú</h1>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddProduct}>
          Agregar
        </Button>
      </Row>
      
      <Search
        placeholder="Buscar productos"
        onSearch={handleSearch}
        enterButton
        style={{ marginBottom: '20px', maxWidth: '300px' }}
      />
      <Spin spinning={loading} tip="Cargando...">
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </Spin>
      <Pagination
        style={{ marginTop: '10px' }}
        align="end"
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleTableChange}
      />

      <Modal
        title="Agregar Producto"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Descripción"
            name="description"
            rules={[{ required: true, message: 'Por favor ingrese una descripción' }]}
          >
            <AntdInput />
          </Form.Item>
          <Form.Item
            label="Categoría"
            name="category_id"
            rules={[{ required: true, message: 'Por favor seleccione una categoría' }]}
          >
            <Select placeholder="Seleccionar categoría">
              {categories.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Precio"
            name="price"
            rules={[{ required: true, message: 'Por favor ingrese el precio' }]}
          >
            <AntdInput type="number" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Imagen"
            valuePropName="file"
            rules={[{ required: true, message: 'Por favor suba una imagen' }]}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Impide la subida automática
            >
              <Button icon={<UploadOutlined />}>Subir Imagen</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Agregar Producto
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductsPage;
