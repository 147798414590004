import apiClient from './apiConfig';

interface Connection {
  id: number;
  name: string;
  password: string;
  created_at: string;
  updated_at: string;
}

interface ConnectionsResponse {
  success: boolean;
  data: Connection[];
  message: string;
}

export const fetchConnections = async (): Promise<Connection[]> => {
  try {
    const response = await apiClient.get<ConnectionsResponse>('/connections');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

export const createQuestion = async (connection: Omit<Connection, 'id' | 'created_at' | 'updated_at'>): Promise<Connection> => {
  try {
    const response = await apiClient.post<Connection>('/connections', connection);
    return response.data;
  } catch (error) {
    console.error('Error creating connection:', error);
    throw error;
  }
};

export const updateQuestion = async (id: number, connection: Omit<Connection, 'id' | 'created_at' | 'updated_at'>): Promise<Connection> => {
  try {
    const response = await apiClient.put<Connection>(`/connections/${id}`, connection);
    return response.data;
  } catch (error) {
    console.error('Error updating connection:', error);
    throw error;
  }
};
