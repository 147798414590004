import apiClient from './apiConfig';

interface Question {
  id: number;
  question: string;
  answer: string;
  order: number;
  created_at: string;
  updated_at: string;
}

interface QuestionsResponse {
  success: boolean;
  data: Question[];
  message: string;
}

export const fetchQuestions = async (): Promise<Question[]> => {
  try {
    const response = await apiClient.get<QuestionsResponse>('/questions');
    return response.data.data.sort((a, b) => a.order - b.order);
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

export const createQuestion = async (question: Omit<Question, 'id' | 'created_at' | 'updated_at'>): Promise<Question> => {
  try {
    const response = await apiClient.post<Question>('/questions', question);
    return response.data;
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;
  }
};

export const updateQuestion = async (id: number, question: Omit<Question, 'id' | 'created_at' | 'updated_at'>): Promise<Question> => {
  try {
    const response = await apiClient.put<Question>(`/questions/${id}`, question);
    return response.data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};
