import apiClient from './apiConfig';

interface AdditionalImage {
  id: number;
  promotion_id: number;
  image_path: string;
  created_at: string;
  updated_at: string;
}

// Definición de la estructura de una promoción
interface Promotion {
  id: number;
  title: string;
  description: string;
  main_image?: File | string;
  user_id: number;
  is_active: boolean;
  expiry_date: string;
  images: AdditionalImage[];
}

interface PromotionResponse {
  success: boolean;
  data: Promotion[];
  message: string;
}

const promotionService = {
  // Crear una nueva promoción
  createPromotion: async (formData: FormData): Promise<Promotion> => {
    const response = await apiClient.post<Promotion>('/promotions', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  },

  // Obtener todas las promociones
  getAllPromotions: async (): Promise<Promotion[]> => {
    const response = await apiClient.get<PromotionResponse>('/promotions');
    return response.data.data;
  },

  // Obtener una promoción por su ID
  getPromotionById: async (id: number): Promise<Promotion> => {
    const response = await apiClient.get<Promotion>(`/promotions/${id}`);
    return response.data;
  },

  // Actualizar una promoción existente
  updatePromotion: async (id: number, formData: FormData): Promise<Promotion> => {
    const response = await apiClient.post<Promotion>(`/promotions/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  },

  // Eliminar una promoción
  deletePromotion: async (id: number): Promise<void> => {
    await apiClient.delete(`/promotions/${id}`);
  },
};

export default promotionService;
export type { Promotion, AdditionalImage };
